<template>
	<div class="content-wrap">
		<!--<h1>财务年度报表</h1>-->
		<div class="ctms-action ctms-action_right">
			<Select
				v-model="searchYear"
				:transfer="true"
				placeholder="请选择年份"
				@on-change="onSwitch"
				style="width: 150px"
			>
				<Option
					v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 50]"
					:value="item"
					:key="index"
					>最近{{ item }}年
				</Option>
			</Select>
		</div>
		<!--图表-->
		<v-chart
			v-if="sourceData.fields && sourceData.fields.length"
			:forceFit="true"
			:height="dataGridHeight"
			:data="createDataSet(sourceData.data, sourceData.fields)"
		>
			<v-tooltip />
			<v-axis />
			<v-legend />
			<v-stack-bar position="收入*支出" color="name" :adjust="adjust" />
		</v-chart>
	</div>
</template>

<script>
import api from "@/api/report/report"
import { mapState } from "vuex"

const { apiGetYearReport } = api
const DataSet = require("@antv/data-set")

export default {
	name: "AnnualFinancialStatement",
	data() {
		return {
			searchYear: 1,
			sourceData: {},
			adjust: [
				{
					type: "dodge",
					marginRatio: 1 / 32
				}
			]
		}
	},
	created() {
		this.onSwitch()
	},
	methods: {
		onSwitch() {
			this.$asyncDo(async () => {
				this.sourceData = await apiGetYearReport(this.searchYear)
			})
		},
		createDataSet(sourceData = [], fields = []) {
			const dv = new DataSet.View().source(sourceData)
			dv.transform({
				type: "fold",
				fields,
				key: "收入",
				value: "支出"
			})
			return dv.rows
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 80
		})
	}
}
</script>
<style scoped>
.ctms-action_right {
	display: flex;
	justify-content: flex-end;
}
</style>
